.home-heading {
    padding-left: 16px;
    margin-top: 8px;
}

.kibana-iframe,
.spinner-container {
    width: 100%;
    height: calc(100vh - 110px);
    border: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

/* Workaround to hide Kibana top menu */
.kibana-iframe {
    margin-top: -48px;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner-container > .spinner-border {
    width: 100px;
    height: 100px;
}
