main {
    margin: auto;
    padding: 40px 15px 40px 15px;
    margin-top: 20px;
    width: 70%;
    min-width: 800px;
    text-align: center;
    border: 1px solid #cbcbcb;
    background: #fff;
    overflow: hidden;
}

.success {
    color: #0baf4a;
}

.fail {
    color: #f54141e3;
}

.enlarge {
    font-size: 2.5em;
    vertical-align: middle;
    padding-bottom: 6px;
    padding-left: 3px;
}

.hidden {
    display: none;
}

.sink-more {
    margin-top: 40px;
}

button {
    border-radius: 5px 5px;
    padding: 15px 32px;
    text-align: center;
    font-weight: 600;
    font-size: 1em;
    cursor: pointer;
}

.btn-info {
    background-color: #2a60c8;
    color: white;
    border: 1px solid #2455a3;
}

.btn-info:hover {
    background-color: #2a60c8;
}

.btn-info:active,
.btn-info:focus {
    background-color: #2a60c8 !important;
    border: 1px solid black !important;
    box-shadow: none !important;
}

.progress-bar {
    display: inherit;
    flex-direction: inherit;
    justify-content: inherit;
    overflow: inherit;
    text-align: inherit;
    white-space: inherit;
    background-color: inherit;
    transition: inherit;
    color: #4c4c4c;
    counter-reset: step;
    width: 950px;
    height: 60px;
    margin: auto;
    margin-bottom: 50px;
    padding: 0;
}

.progress-bar li {
    list-style-type: none;
    width: 33%;
    float: left;
    text-transform: uppercase;
    text-emphasis: 1;
    text-align: center;
    position: relative;
}

.progress-bar li::before {
    width: 40px;
    height: 40px;
    content: counter(step);
    line-height: 36px;
    counter-increment: step;
    display: block;
    background-color: #fff;
    border: 2px solid #dddddd;
    border-radius: 50%;
    margin: 0 auto 10px auto;
}

.progress-bar li:after {
    width: 100%;
    height: 4px;
    content: "";
    position: absolute;
    background-color: #dddddd;
    top: 18px;
    left: -50%;
    z-index: -1;
}

.progress-bar li:first-child:after {
    content: none;
}

.progress-bar li.current::before {
    border: 4px solid;
    padding-top: 3px !important;
}

.progress-bar li.active {
    color: #4c4c4c;
}

.progress-bar li.active::before {
    border-color: #ff4f1f;
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" fill="rgb(255,79,31)" viewBox="0 65 513 447"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"/></svg>');
    padding-top: 5px;
}

.progress-bar li.active + li:after {
    background-color: #dddddd;
}

.title {
    padding: 25px;
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    color: #2a60c8;
    font-family: "Lato", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
}

.wait-title {
    padding: 15px;
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    font-family: "Lato", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
}

.wait-content {
    padding: 15px;
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    font-family: "Lato", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
}

.message {
    width: 700px;
    margin: auto;
    border: 1px solid #dddddd;
    border-bottom: 3px solid #ddd;
}

.message-title {
    padding: 16px;
    color: white;
    font-size: 1em;
    font-weight: bold;
    text-align: left;
    background-color: #2a60c8;
}

.message-content {
    background-color: #fff;
    padding: 16px;
    padding-left: 32px;
    text-align: left;
}

.loading-overlay {
    position: fixed;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-around;
    background: rgba(240, 240, 255, 0.85);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 2147483647;
}

.loading-overlay-element {
    text-align: center;
}

.loading-overlay-element > .spinner-border {
    width: 100px;
    height: 100px;
}

.loading-overlay-text {
    padding-top: 100px;
    font-size: 20.4px;
    color: #2a60c8;
}
