/* 
*   Custom CSS-style adhering to the current (June 2018) version 
*   of the PureCloud App Foundry Style Guide
*       Found at: https://developer.mypurecloud.com/partners/
*/

body {
    color: #0F465D;
    font-family: “HelveticaNeue”, “Helvetica”, “Arial”, sans-serif;
    font-size: 14px;
}

div.field-title {
    color: #1997CA;
    font-size: 18px;
    width: 100px;
}

div.field-value {
    color: #4498B4;
    text-align: right;
}

div.floating{
    display: inline-block;
}

.secondary-font {
    font-family: "Lato", “HelveticaNeue”, “Helvetica”, “Arial”, sans-serif;
}

div.test-area{
    margin-top: 10px;
    padding: 10px;
    background-color: #FFEAEA;
}

/* Addition to Bulma classes */

h1.title{
    color: #0F465D;
}

.subtitle{
    color: #1997CA;
}

option{
    left:0;
}

table {
    border-collapse: collapse;
  }
  
  table, th, td {
    border: 0;
    font-size: 14px;
    font-family: Lato,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: normal;
    padding-top: 10px;
    padding-right: 6px;
    padding-bottom: 10px;
    padding-left: 6px;
  }
  
  th, td {
    padding-top: 10px;
    padding-right: 6px;
    padding-bottom: 10px;
    padding-left: 6px;
  }

  button {
    border-radius: 5px 5px;
    padding: 15px 32px;
    text-align: center;
    font-weight: 600;
    font-size: 1em;
    cursor: pointer;
}

.btn-info {
    background-color: #34b3dd;
    color: white;
    border: 1px solid #34b3dd;
    border-bottom: 3px solid #24a3cd;
}

.btn-info:hover {
    background-color: #25c0df;
}

.right {
    float: right;
}

.left {
    float: left;
}

#txtSubscribe, #ddlQueues {
    font-family: Lato,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 21px;
}