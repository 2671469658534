.settings-heading {
   margin-top: 8px;
   margin-bottom: 18px;
}

.form-control::placeholder {
   color: #bfbfbf;
}

.settings-iframe {
   width: 100%;
   height: calc(100vh - 75px);
   border: 0;
   margin: 8px 0 5px 0;
   padding: 0 0 0 15px;
   overflow: hidden scroll;
}
