.help-container {
    width: 100%;
    height: calc(100vh - 75px);
    border: 0;
    margin: 8px 0 5px 0;
    padding: 0 15px 0 15px;
    overflow: hidden scroll;
    counter-reset: h1counter figurecounter;
}

.help-container h1 {
    margin-top: 15px;
    font-size: 1.75rem;
    counter-reset: h2counter;
}

.help-container h1::before {
    counter-increment: h1counter;
    content: counter(h1counter) ". ";
}

.help-container h2 {
    margin-top: 40px;
    font-size: 1.5rem;
    counter-reset: h3counter;
}

.help-container h2::before {
    counter-increment: h2counter;
    content: counter(h1counter) "." counter(h2counter) ".\0000a0\0000a0";
}

.help-container h3 {
    margin-top: 25px;    
    font-size: 1.25rem;
}

.help-container h3::before {
    counter-increment: h3counter;
    content: counter(h1counter) "." counter(h2counter) "." counter(h3counter) ".\0000a0\0000a0";
}

.help-container img {
    max-width: 100%;
}

.help-container figure {
    text-align: center;
}

.help-container figcaption {
    margin: 10px 0 15px 0;
    font-size: 0.75rem;
    font-style: italic;
}

.help-container figcaption::before {
    counter-increment: figurecounter;
    content: "Figure " counter(figurecounter) ". ";
}